/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, location }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const defaultTitle = site.siteMetadata?.title
  const seoTitle = defaultTitle ? `${title} | ${defaultTitle}` : title;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : null}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: seoTitle,
        },
        {
          property: `og:locale`,
          content: lang,
        },
        {
          property: `og:site_name`,
          content: defaultTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: seoTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type='application/ld+json' dangerouslySetInnerHTML={{
        __html: `
        {"@context":"http:\\/\\/schema.org","@type":"WebSite","@id":"#website","url":"https:\\/\\/keeperforms.com\\/","name":"keeperforms","potentialAction":{"@type":"SearchAction","target":"https:\\/\\/keeperforms.com\\/?s={search_term_string}","query-input":"required name=search_term_string"}}
      `
      }}/>
      <script type='application/ld+json' dangerouslySetInnerHTML={{
        __html: `
        {"@context":"http:\\/\\/schema.org","@type":"Organization","url":"https:\\/\\/keeperforms.com\\/","sameAs":["https:\\/\\/www.facebook.com\\/keeperforms\\/","https:\\/\\/www.linkedin.com\\/company\\/18212043","https:\\/\\/twitter.com\\/KeeperForms"],"@id":"#organization","name":"Keeper Forms","logo":"https:\\/\\/keeperforms.com\\/wp-content\\/uploads\\/2018\\/01\\/keeperforms-logo-dark@2x-1.png"}
      `
      }}/>
      <link rel="canonical" href={`${site.siteMetadata?.siteUrl}${location?.pathname}`} />

    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `he`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO;
