import React, {useEffect} from 'react'
import PageWrapper from "../components/PageWrapper"
import SEO from "../components/seo"

import imgE from "../assets/image/inner-page/png/thank-you.png"
import {OutboundLink} from 'gatsby-plugin-google-gtag';

const ThankYouForSendingASignupRequest = () => {
  useEffect(() => {
      typeof window !== "undefined" && window.gtag('event', 'conversion', {'send_to': 'AW-1064231909/uixECMfGreoBEOXHu_sD'});
      typeof window !== "undefined" && window.ccConversion && typeof(window.ccConversion) === 'function' && window.ccConversion(250);
  });

  return (
    <>
      <SEO title="תודה ששלחת בקשת הרשמה" />
      <PageWrapper themeConfig={{
        footerStyle: "none",
        footerClassName: "pt-13 pt-lg-25 pb-13 pb-lg-10",
      }}>
        <div className="pt-20 pt-md-14 pb-15 pt-lg-24 pb-md-19 pb-lg-25">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                <div className="text-center">
                  <div
                    className="mb-8 mb-lg-16"
                    data-aos="fade-up"
                    data-aos-duration={500}
                  >
                    <img className="w-xs-auto max-h-250" src={imgE} alt="" />
                  </div>
                  <h1
                    className="font-size-13 mb-8"
                    data-aos="fade-up"
                    data-aos-duration={700}
                  >
                    תודה!
                  </h1>
                  <p
                    className="font-size-7 mb-4"
                    data-aos="fade-up"
                    data-aos-duration={900}
                  >
                    אנחנו שמחים שביקשת להירשם לפרופרטיים.<br />
                  </p>
                  <div>
                    <p
                      className="font-size-4 mt-4 text-right"
                      data-aos="fade-up"
                      data-aos-duration={1000}
                    >
                      היחס האישי חשוב לנו ועל כן, אחד מנציגנו ייצור איתך קשר בקרוב,
                      בדרך כלל תוך מספר שעות, בכדי להכיר את הצרכים שלך ולהתאים את הפיתרון הטוב ביותר עבורך.<br /><br />
                      <span className="pb-2" style={{display: "block"}}>
                      בינתיים, ניתן לבקר במרכז המידע שלנו אשר כולל:<br />
                      </span>
                      <ul>
                        <li> שאלות נפוצות</li>
                        <li> חדשות ועדכונים</li>
                        <li> מדריכי שימוש במערכת</li>
                      </ul><br />
                      שיהיה לך יום נפלא!
                    </p>
                  </div>
                  <div>
                    <div
                      className="pt-11 d-flex flex-row flex-wrap "
                      data-aos="fade-up"
                      data-aos-duration={1100}
                    >
                      <OutboundLink
                          href="//help.propertime.co.il/he/support/home"
                        className="btn btn-primary w-100 rounded-5 text-white m-2 max-w-193"
                          rel="noopener"
                      >
                        למרכז המידע
                      </OutboundLink>
                      <OutboundLink
                          href="//propertime.co.il"
                        className="btn btn-outline-primary w-100 rounded-5 m-2 max-w-193"
                          rel="noopener"
                      >
                        לאתר פרופרטיים
                      </OutboundLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  )
}

export default ThankYouForSendingASignupRequest

